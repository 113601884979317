@import url('./vars.css');

.cards {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.card {
    gap: 10px;
    padding: 10px 25px;
    border-radius: 25px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-background-color);
}

.card__name {
    text-transform: uppercase;
    font-weight: bold;
}

.card__value {
    font-family: 'Lexend Giga', monospace;
    font-weight: 900;
    font-size: 28px;
}

.card__unit {
    font-size: 14px;
}