@import url('./vars.css');
@import url('./animations.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

html {
    scroll-behavior: smooth;
}

body {
    color: var(--on-background-color);
    background-color: var(--background-color);
    font-family: var(--font-family);
}

a, p, span, button, h1, h2, h3, h4, h5, h6, legend, label {
    color: var(--on-background-color);
}

a, p, span, input, textarea, button, legend, label {
    font-family: var(--font-family);
    font-size: var(--font-size);
}

a {
    color: var(--on-background-color);
    text-decoration: none;
}

p {
    margin: 0;
    max-width: 600px;
}

h1 {
    font-size: var(--font-size-h1);
}

h2 {
    font-size: var(--font-size-h2);
}

h3 {
    font-size: var(--font-size-h3);
}

button {
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
}

main {
    padding: 60px 60px 0 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    min-height: 90vh;
}

fieldset {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: none;
}

fieldset label {
    font-weight: 500;
    margin-bottom: 10px;
    margin-left: 10px;
}

fieldset legend {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
}

form {
    width: 100%;
    max-width: 600px;
}

form fieldset {
    width: 100%;
}

form input:not([type='submit']), form textarea {
    border: none;
    outline: none;
    box-sizing: border-box;
    background-color: var(--background-color);
    padding: 10px 20px;
    border-radius: var(--border-radius);
    border: 1px solid var(--border);
    width: 90%;
    resize: none;
}

.dark form input:not([type='submit']), .dark form textarea {
    background-color: var(--surface-color);
    color: var(--on-surface-color);
    border: 0;
}

footer {
    margin-top: 50px;
    padding: 25px 50px;
}

/* USEFUL CLASSES */

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-center {
    justify-content: center;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.align-stretch {
    align-items: stretch;
}

.heading-1 {
    font-size: var(--font-size-h1);
}

.heading-2 {
    font-size: var(--font-size-h2);
}

.heading-3 {
    font-size: var(--font-size-h3);
}

.heading-1, .heading-2, .heading-3 {
    transition: var(--transition); 
}

.border-box {
    box-sizing: border-box;
}

.gap-5 {
    gap: 5px;
}

.gap-10 {
    gap: 10px;
}

.gap-20 {
    gap: 20px;
}

.gap-25 {
    gap: 25px;
}

.gap-30 {
    gap: 30px;
}

.gap-40 {
    gap: 40px;
}

.gap-50 {
    gap: 50px;
}

.no-wrap {
    flex-wrap: nowrap;
}

.wrap {
    flex-wrap: wrap;
}

.section-primary {
    background-color: var(--background-color);
    color: var(--on-background-color);
}

section.banner {
    min-height: 50vh;
}

section.banner * {
    color: #fff;
    text-shadow: 0 0 1px transparent,0 1px 2px rgba(0,0,0,.8);
}

section.section-primary, section.banner {
    width: 100%;
    box-sizing: border-box;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

section.banner .banner-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 60px;
    left: 0;
    filter: blur(3px);
    z-index: -1;
}

.dark section.banner .banner-background {
    filter: blur(1px);
    opacity: 0.5;
}

.section-split {
    display: grid;
    gap: 5.375rem;
    align-items: center;
    justify-content: center;
    max-width: 64rem;
    margin: 0 auto;
    grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-reverse>div:first-child {
    order: 1;
}

@media (max-width: 768px) {
    .section-split {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        gap: 0;
    }

    .grid-reverse>div:first-child, .section-split>div:first-child {
        order: 1;
    }
}
.no-border {
    border: none;
}

.no-outline {
    outline: none;
}

.min-content {
    width: min-content;
}

.max-content {
    width: max-content;
}

.fit-content {
    width: fit-content;
}

.pointer {
    cursor: pointer;
}

.radius {
    border-radius: var(--border-radius);
}

.radius-3 {
    border-radius: 3px;
}

.rounded {
    border-radius: 100%;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.cover {
    object-fit: cover;
}

.border {
    border: 1px solid var(--secondary-color);
}

.large-border {
    border: 2px solid var(--secondary-color);
}

.lexend {
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -3px;
}

.thin {
    font-weight: 100;
}

.light {
    font-weight: 300;
}

.regular {
    font-weight: 400;
}

.medium {
    font-weight: 500;
}

.semi-bold {
    font-weight: 600;
}

.bold {
    font-weight: 700;
}

.extra-bold {
    font-weight: 800;
}

.black {
    font-weight: 900;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.surface {
    background-color: var(--surface-color);
    color: var(--on-surface-color);
}

.primary-text {
    color: var(--primary-color);
}

.secondary-text {
    color: var(--secondary-color);
}

.third-text {
    color: var(--third-color);
}

.on-surface-text {
    color: var(--on-surface-color);
}

.on-background-text {
    color: var(--on-background-color);
}

.white-text {
    color: #fff;
}

.italic {
    font-style: italic;
}

.border-top {
    border-top: 1px solid var(--border);
}

.border-bottom {
    border-bottom: 1px solid var(--border);
}

.no-margin {
    margin: 0;
}

.disabled-clickable {
    opacity: 0.5;
}

.disabled-clickable:hover {
    opacity: 1;
}

.text-center {
    text-align: center;
}

.text-bigger {
    font-size: 1.2rem;
}

.transition {
    transition: var(--transition);
}

.rotate-180 {
    transform: rotate(180deg);
}

.hidden {
    display: none;
}

.error {
    color: var(--error-color);
}

.link {
    color: var(--primary-color);
    text-decoration: underline;
    text-decoration-style: dotted;
}

.link:hover {
    text-decoration-style: solid;
}

.block {
    display: block;
}

.margin-top {
    margin-top: 10px;
}

.margin-bottom {
    margin-bottom: 10px;
}

.smooth-shadow {
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.2);
}

.text-shadow {
    text-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
}

.big-text {
    font-size: 2rem;
}

.font-xs {
    font-size: var(--font-size-xs);
}

.font-s {
    font-size: var(--font-size-s);
}

.font-m {
    font-size: var(--font-size-m);
}

.font-l {
    font-size: var(--font-size-l);
}

.font-xl {
    font-size: var(--font-size-xl);
}

.font-xxl {
    font-size: var(--font-size-xxl);
}

/* STYLES */

.btn {
    padding: 10px 20px;
    border-radius: 50px;
    transition: ease-in-out 0.3s;
    cursor: pointer;
    text-align: center;
}

.btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
    z-index: -1;
}

.btn-primary {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
}

.btn-primary:not([disabled]):hover {
    background-color: var(--primary-color-dark);
}

.btn-secondary {
    background-color: var(--secondary-color);
    color: var(--on-secondary-color);
}

.btn-secondary:not([disabled]):hover {
    background-color: var(--secondary-color-dark);
}

.btn-third {
    background-color: var(--third-color);
    color: var(--on-third-color);
}

.btn-third:not([disabled]):hover {
    background-color: var(--third-color-dark);
}

.btn-transparent {
    background-color: transparent;
    color: var(--primary-color);
    border: 2px solid var(--primary-color);
}

.btn-transparent:not([disabled]):hover {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
}

.btn-red {
    background-color: var(--error-color);
    color: #fff;
}

.btn-red:not([disabled]):hover {
    background-color: var(--error-color-dark);
}

.btn-loading {
    background-image: url('https://upload.wikimedia.org/wikipedia/commons/a/ad/YouTube_loading_symbol_3_%28transparent%29.gif?20140201131911');
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: 12px center;
    padding-left: 40px;
    cursor: wait;
    opacity: 0.8;
}

.search-bar input {
    border: none;
    outline: none;
    padding: 10px 20px;
    background-color: var(--secondary-background-color);
    border: 1px solid var(--border);
    border-radius: var(--border-radius);
    font-family: var(--font-family);
    font-size: var(--font-size);
}

.dark .search-bar input {
    color: var(--on-surface-color);
    background-color: var(--surface-color);
    border: 0;
}

.search-bar input:focus {
    outline: 1px solid var(--primary-color);
    box-shadow: var(--primary-color) 0px 0px 5px;
}

.list-children {
    gap: 10px;
}

.list-item {
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid var(--border);
    margin-top: 5px;
    padding-bottom: 5px;
}

.list-item > * {
    padding: 15px 10px;
    border-radius: var(--border-radius);
    transition: ease-in-out 0.15s;
}

.list-item:hover > * {
    background-color: var(--secondary-background-color-light);
}

.list-item:last-child {
    border-bottom: none;
}

.clickable {
    cursor: pointer;
}

.clickable * {
    transition: var(--transition);
}

.clickable:hover * {
    color: var(--primary-color);
}

.clickable-bg {
    cursor: pointer;
    transition: var(--transition);
}

.clickable-bg:hover {
    background-color: var(--secondary-background-color-light);
}

.minute {
    font-weight: 700;
    font-size: 1.2rem;
    padding: 5px 10px;
    width: 29px;
    border-radius: 5px;
    text-align: center;
    background-color: var(--primary-color-dark);
    color: var(--on-primary-color) !important;
}

.container {
    display: flex;
    flex-direction: column;
    padding: 10px 25px;
    background-color: var(--secondary-background-color);
    border-radius: var(--border-radius);
    width: 100%;
    max-width: 600px;
}

.primary-bg {
    background-color: var(--primary-color);
}

.primary-bg-light {
    background-color: var(--primary-color-light);
}

.primary-bg-dark {
    background-color: var(--primary-color-dark);
}

.secondary-bg {
    background-color: var(--secondary-background-color);
}

.secondary-bg-light {
    background-color: var(--secondary-background-color-light);
}

.secondary-bg-dark {
    background-color: var(--secondary-background-color-dark);
}

.third-bg {
    background-color: var(--third-color);
}

.third-bg-light {
    background-color: var(--third-color-light);
}

.third-bg-dark {
    background-color: var(--third-color-dark);
}

.padding {
    padding: 15px 25px;
}

.padding-tb {
    padding-top: 15px;
    padding-bottom: 15px;
}

.padding-lr {
    padding-left: 25px;
    padding-right: 25px;
}

/* OTHERS */

.dropdown {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--secondary-background-color);
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    margin-top: 19px;
    z-index: 1;
    transition: ease-in-out 0.3s;
}

.dropdown .fa-angle-down {
    transition: ease-in-out 0.3s;
}

.dropdown-content>* {
    padding: 12px 16px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    gap: 10px;
    border-radius: var(--border-radius);
}

.dropdown-content>*:hover {
    background-color: #ddd;
    color: #000;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.dropdown:hover .dropbtn {
    background-color: #3e8e41;
}

.dropdown:hover .fa-angle-down {
    transform: rotate(-180deg);
}

/* PopUp Component */

.popup-content {
    margin: auto;
    background: var(--background-color);
    width: 75%;
    max-width: 650px;
    padding: 35px;
    border-radius: var(--border-radius);
    overflow-y: auto;
    max-height: 80vh;
}

.popup-content .close {
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: ease-in-out 0.15s;
}

.popup-content .close:hover {
    transform: rotate(90deg);
}

.popup-content .close path {
    stroke: var(--on-background-color);
}

.popup-arrow {
    color: var(--on-background-color);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.2s;
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}


@media all and (max-width: 520px) {
    .awssld__controls {
      visibility: visible !important; 
    } 
}

.tooltip-content {
    padding: 5px 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    white-space: nowrap;
    width: max-content !important;
    animation: fadeIn ease-in 1;
    border-radius: 8px;
    border: 1px solid var(--secondary-color);
}

.select__control, .select__menu, .select__menu-list, .select__option {
    border-radius: var(--border-radius) !important;
}

.select__menu {
    background-color: var(--secondary-background-color) !important;
}

.progress-bar {
    width: 100%;
    height: 1rem;
    background-color: var(--secondary-background-color-light);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.progress-bar .progress {
    height: 100%;
    background-color: var(--primary-color);
    transition: ease-in-out 0.3s;
}