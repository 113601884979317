@import url('./vars.css');

.nav-bar {
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    background-color: var(--background-color);
    position: fixed;
    box-sizing: border-box;
}

.nav-bar .logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-bar .logo img {
    width: 100px;
}

.nav-bar__menu ul {
    display: flex;
    gap: 20px;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
}

.nav-bar__menu ul li>span>a {
    color: var(--on-background-color);
    text-decoration: none;
    font-weight: 500;
    transition: var(--transition);
    display: flex;
    gap: 7px;
    align-items: center;
    height: 40px;
    font-family: 'Lexend Giga', sans-serif;
    letter-spacing: -3px;
}

.nav-bar__menu ul li>span:hover, .nav-bar__menu ul li .active, .nav-bar__menu ul li>span>a:hover {
    color: var(--third-color);
}

.side-menu {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 90%;
    max-width: 300px;
    background-color: var(--background-color);
    z-index: 4;
    transition: var(--transition);
}

.side-menu__overlay {
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: 3;
    transition: opacity 0.3s ease-in-out;
}

.nav-bar__menu .burger {
    position: relative;
    z-index: 5;
}

.side-menu__content {
    margin-top: 70px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    z-index: 4;
    gap: 30px;
}

.hidden {
    transform: translateX(100%);
}

.invisible {
    opacity: 0;
}