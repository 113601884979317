@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Lexend+Giga:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    --primary-color: #df3232;
    --primary-color-light: #d33d3d;
    --primary-color-light-transparent: #d33d3d2d;
    --primary-color-dark: #b62121;
    --secondary-color: #707793;
    --secondary-color-light: #9e9e9e;
    --secondary-color-dark: #5d6279;
    --third-color: #006233;
    --third-color-light: #007a3d;
    --third-color-dark: #004d28;
    --on-primary-color: #fff;
    --on-secondary-color: #fff;
    --background-color: #fff;
    --background-color-light: #fff;
    --on-background-color: #0e0e0e;
    --secondary-background-color: #f7f9f9;
    --secondary-background-color-light: #eeeeee;
    --secondary-background-color-dark: #e0e0e0;
    --on-secondary-background-color: var(--on-background-color);
    --surface-color: #272934;
    --on-surface-color: #fff;
    --error-color: #f44336;
    --error-color-dark: #d0382d;
    --on-error-color: #fff;
    --border: #cfd9de;
    --font-family: 'Montserrat', sans-serif;
    --font-family-secondary: 'Montserrat', sans-serif;
    --font-family-secondary-spacing: inherit;
    --font-size: 16px;
    --font-weight: 400;
    --line-height: 1.5;
    --border-radius: 15px;
    --box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.07);
    --transition: 0.3s;
    --font-size-xs: 12px;
    --font-size-s: 14px;
    --font-size-m: 16px;
    --font-size-l: 24px;
    --font-size-xl: 32px;
    --font-size-xxl: 36px;
    --font-size-h1: 40px;
    --font-size-h2: 38px;
    --font-size-h3: 20px;
}

body.dark {
    --primary-color: #ca3030;
    --primary-color-light: #c94646;
    --primary-color-light-transparent: #c946462d;
    --primary-color-dark: #8a2b2b;
    --secondary-color: #707793;
    --secondary-color-light: #9e9e9e;
    --secondary-color-dark: #5d6279;
    --third-color: #309c68;
    --third-color-light: #3abf7f;
    --third-color-dark: #2a885a;
    --on-primary-color: #fff;
    --on-secondary-color: #fff;
    --background-color: #272934;
    --background-color-light: #2f3440;
    --on-background-color: #fff;
    --secondary-background-color: #1e2025;
    --secondary-background-color-light: #262930;
    --on-secondary-background-color: #fff;
    --surface-color: #fff;
    --on-surface-color: #141414;
    --error-color: #f44336;
    --error-color-dark: #d0382d;
    --on-error-color: #fff;
    --border: #4d5052;
    --font-family: 'Montserrat', sans-serif;
    --font-family-secondary: 'Montserrat', sans-serif;
    --font-family-secondary-spacing: inherit;
    --font-size: 16px;
    --font-weight: 400;
    --line-height: 1.5;
    --border-radius: 15px;
    --box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.07);
    --transition: 0.3s;
    --font-size-xs: 12px;
    --font-size-s: 14px;
    --font-size-m: 16px;
    --font-size-l: 24px;
    --font-size-xl: 32px;
    --font-size-xxl: 36px;
    --font-size-h1: 40px;
    --font-size-h2: 38px;
    --font-size-h3: 20px;
}

@media(max-width:768px) {
    body, body.dark {
        --font-size: 14px;
        --font-size-h1: 30px;
        --font-size-h2: 28px;
        --font-size-h3: 18px;
    }

}

:root {
    --default-gap: 8px;
    --section-width: 343px;
    --section-width-s: 343px;
    --section-width-lg: 100%;
    --gap-2xs: calc(var(--default-gap)*0.25);
    --gap-xs: calc(var(--default-gap)*0.5);
    --gap-s: calc(var(--default-gap));
    --gap-sm: calc(var(--default-gap)*1.5);
    --gap-m: calc(var(--default-gap)*2);
    --gap-l: calc(var(--default-gap)*4);
    --gap-xl: calc(var(--default-gap)*8)
}

@media(min-width:768px) {
    :root {
        --default-gap: 12px;
        --section-width: 880px;
        --section-width-s: 600px;
        --section-width-lg: 1120px
    }
}