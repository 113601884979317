@import url('./vars.css');

.goal-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    width: 20px;
    transition: var(--transition);
    opacity: 0.5;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
}

.goal-navigation:hover {
    opacity: 1;
}

.dark .goal-navigation {
    filter: invert(1);
}

.goal-navigation.left {
    background-image: url('../../../public/assets/img/icons/left.svg');
}

.goal-navigation.right {
    background-image: url('../../../public/assets/img/icons/right.svg');
}

@media (min-width: 700px) {
    .goal-navigation {
        width: 40px;
    }
}