.morph {
    animation: morph 8s ease-in-out infinite 1s;
}

@keyframes morph {
    0% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
   }
    50% {
        border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
   }
    100% {
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
   }
}

.pop {
    animation: slideIn 0.3s;
}

@-webkit-keyframes slideIn {
    0% {
        transform: translateY(20px);
   }
    100% {
        transform: translateY(0);
   }
}

@keyframes slideIn {
    0% {
        transform: translateY(20px);
   }
    100% {
        transform: translateY(0);
   }
}

.hover-translate {
    transition: transform 0.3s ease;
}

.hover-translate:hover {
    transform: translate(0, -3px);
}

.fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.5s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
   }
    100% {
        opacity: 1;
   }
}

/* LOADERS */



.loader {
    width: 48px;
    height: 48px;
    border: 3px solid var(--primary-color);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 1s linear infinite;
}

.loader:after {
    content: '';
    position: absolute;
    width: 48px;
    height: 48px;
    border: 3px solid var(--primary-color);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 1s linear infinite;
}

@keyframes scaleUp {
    0% {
        transform: translate(-50%, -50%) scale(0)
    }

    60%,
    100% {
        transform: translate(-50%, -50%) scale(1)
    }
}

@keyframes pulse {

    0%,
    60%,
    100% {
        transform: scale(1)
    }

    80% {
        transform: scale(1.2)
    }
}

.loader-horizontal {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--primary-color);
    box-shadow: 32px 0 var(--primary-color), -32px 0 var(--primary-color)f;
    position: relative;
    animation: flash 0.5s ease-out infinite alternate;
  }
  
  @keyframes flash {
    0% {
      background-color: var(--primary-color-light-transparent);
      box-shadow: 32px 0 var(--primary-color-light-transparent), -32px 0 var(--primary-color);
    }
    50% {
      background-color: var(--primary-color);
      box-shadow: 32px 0 var(--primary-color-light-transparent), -32px 0 var(--primary-color-light-transparent);
    }
    100% {
      background-color: var(--primary-color-light-transparent);
      box-shadow: 32px 0 var(--primary-color), -32px 0 var(--primary-color-light-transparent);
    }
  }
        